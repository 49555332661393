const data = [
  {
    title: "Chinmaya Vidyalaya - School With A Difference",
    date: "15 February, 2024",
    id: "chinmaya-vidyalaya-school-with-a-difference",
    link: "/blog/chinmaya-vidyalaya-school-with-a-difference",
    content: `<p><strong>“A child without education is like a bird without wings.”</strong><br/>
    - Tibetan Proverb
    </p>
    <p>
    Education is the cornerstone of personal development, social progress, and sustainable development. It empowers individuals to realise their full potential, contribute to the betterment of society, and shape a brighter future for generations to come.
    </p>
    <p>
    Education plays a vital role in preserving and transmitting cultural heritage, traditions, and values from one generation to another. It fosters a sense of identity, belonging, and pride in one's cultural roots, contributing to social cohesion and diversity. It even promotes critical thinking and problem-solving skills, which are essential for addressing societal challenges such as poverty, inequality, and environmental sustainability. Education fosters global awareness, intercultural understanding, and tolerance, preparing individuals to thrive in an increasingly interconnected world.
    </p>
    <p>
    Pujya Gurudev Swami Chinmayananda understood the importance of education in heralding India’s progress and envisioned an education system to create positive and responsible citizens and powerful leaders.
    </p>
    <p>
    Chinmaya Vidyalaya's education philosophy is a broad all-encompassing view of education that looks beyond the narrow purview of only academics and marks. It focuses on every child, helping them discover their unique talent and supporting them in achieving their highest potential. 
    </p>
    <p>
    Comprehensive development of students requires the development of their personality, intellect, spiritual quotient, and emotional quotient, along with a strong focus on extracurricular activities.
    <p/>
    <p>
    Chinmaya Vidyalaya provides a strong value foundation that helps shape their character and guides them to make principled decisions and act with integrity in various life situations. 
    </p>
    <p>
    Swami Chinmayananda believed in the youth of the nation being its largest untapped reservoir of potential and highlighted the importance of exposure to native culture and the necessity of its seamless integration with modern pedagogy for true holistic education.
    </p>
    <p>
    Chinmaya Vidyalaya provides a comprehensive development and education experience by placing the learner as the focal point of all the teaching interventions. It believes the purpose of education is empowerment and enlightenment which makes it the School With a Difference.
    </p>
    `,
  },
  {
    title: "Janani Janmabhoomi Swargadapi Gariyasi",
    date: "1 February, 2024",
    id: "janani-janmabhoomi-swargadapi-gariyasi",
    link: "/blog/janani-janmabhoomi-swargadapi-gariyasi",
    content: `<p>The beautiful Sanskrit phrase "Janani Janmabhoomi Swargadapi Gariyasi," which translates to "Mother and motherland are superior even to heaven," is a profound statement that resonates across cultures and carries timeless significance.
    </p>
    <p>
    This phrase appears in two Shlokas in Valmiki’s Ramayana and emphasises the unique and irreplaceable bond one shares with one’s mother and the place one belongs to. In Indian culture, the mother is considered the embodiment of selfless love, nurturing care, and sacrifice. Similarly, the motherland represents one's roots, heritage, and sense of belonging. Together, they symbolize the highest ideals and values that one cherishes above all else.
    </p><p>
    Indian civilisation is the oldest surviving civilisation and one of its enduring principles has been nation before self. Patriotism is a noble emotion that motivates people to be responsible citizens and work towards the betterment of their nation. It also helps in developing a positive self-identity and makes one more respectful and open towards other cultures.
    </p><p>
    Pujya Gurudev Swami Chinmayananda believed that a nation’s culture emanates from shared values and its continuance depends upon the youth developing pride in their nation. He wanted all Indians to learn more about their heritage and take pride in it.
    </p><p>
    In today's globalised world, where mobility and cultural exchange are commonplace, developing a true understanding of one’s cultural and national heritage is vital to understanding one’s true identity. It also helps one cultivate empathy, understanding and respect for other cultures, which fosters a sense of global citizenship.
    </p><p>
    Chinmaya Vidyalaya believes every school shoulders the great responsibility of moulding the leaders and responsible citizens of tomorrow. The Chinmaya Vision Programme includes knowledge of Indian culture and aims to inculcate patriotism and national pride in every learner as it is a vital part of their comprehensive development.
    </p>`,
  },
  {
    title: "Nurturing Cyber-Savvy Kids",
    date: "15 January, 2024",
    id: "nurturing-cyber-savvy-kids",
    link: "/blog/nurturing-cyber-savvy-kids",
    content: `<p>In today's digital age, children are growing up surrounded by technology, from smartphones and tablets to laptops and gaming consoles. While the internet offers a wealth of opportunities for learning and entertainment, it also poses risks, especially for young, impressionable minds. As parents and educators, it's essential to equip children with the knowledge and skills to navigate the online world safely.</p>

    <p>Children need to understand the importance of safeguarding their personal information, such as their full name, address, phone number, and passwords, from online predators and identity thieves.</p>
    
    <p>Another major concern that needs to be addressed is cyberbullying. Bullying is now not limited to the physical world and inhibitions seem to be much lower online. Cyberbullying, if left unchecked, can lead to very serious consequences. Children need to learn proper online etiquette that teaches them how to recognise and respond to cyberbullying behaviour. It can help prevent them from becoming victims or perpetrators and increase sensitivity in handling such situations.</p>
    
    <p>The parents and teachers have to work together to create an environment where children feel safe in reporting such behaviour too. In case of threats, online violation of privacy or sharing of explicit photographs, legal recourse is available.</p>
    
    <p>Some ways of keeping children safe online are:</p>
    
    <p><strong>Start early</strong> - As soon as children start using any internet resource, introduce cybersecurity concepts and continue to reinforce them as they grow older.  This will teach them to avoid online scams and frauds, such as phishing emails, fake websites, and deceptive pop-up ads.</p>
    
    <p><strong>Use age-appropriate resources</strong> - Utilise age-appropriate books, videos, games, and interactive activities to teach cybersecurity concepts in a fun and engaging way. Find resources specifically designed for children that cover topics such as password security, safe browsing habits, and social media etiquette.</p>
    
    <p><strong>Lead by example</strong> - Be a positive role model for your children by practising good cybersecurity habits. Teach them how to create strong passwords, use privacy settings on social media accounts, and verify the authenticity of websites before sharing personal information.</p>
    
    <p><strong>Encourage open communication</strong> - Create a safe and supportive environment where children feel comfortable discussing their online experiences, concerns, and questions.</p>
    
    <p><strong>Set clear rules and boundaries</strong> - Establish clear rules and boundaries for internet usage, such as limiting screen time, using parental controls and filters, and monitoring online activity.</p>
    
    <p><strong>Empower them to make informed choices</strong> - Teach the children to trust their instincts and make informed decisions about what they share online, who they interact with, and which websites and apps they use. Encourage them to think before they click and to consider the potential consequences of their actions online.</p>
    
    <p>Teaching cybersecurity to children is essential for their safety and well-being in today's digital world. By providing them with the knowledge, skills, and resources they need to navigate the online world safely, we empower them to become responsible digital citizens who can make informed choices and protect themselves from online threats.</p>`,
  },
  {
    title: "Kindness Goals",
    date: "1 January, 2024",
    id: "kindness-goals",
    link: "/blog/kindness-goals",
    content: `<p>
    The purpose of education is to open the mind and enable it to accept a diversity of ideas, people, and opinions. This broadening of the mind also brings acceptance of others, which is the ground for kindness and empathy.
    </p>
    <p>
    Kindness plays a crucial role in creating a compassionate and inclusive society. When individuals actively practice kindness, it sets an example for others and encourages a ripple effect of positive behaviour. It also helps bridge divides, reduce prejudices, and promote understanding among diverse groups of people.
    </p>
    <p>
    At Chinmaya Vidyalaya, the learners are encouraged to practice active kindness as it contributes to their emotional well-being. When children engage in acts of kindness, they experience positive emotions such as happiness, empathy, and gratitude. They cultivate a positive self-image and an enhanced sense of belonging and connectedness with others.
    </p>
    <p>
    In the school environment, learning active kindness helps prevent bullying. It also helps the students differentiate between right and wrong and encourages them to act fairly and compassionately.
    </p>
    <p>
    Kindness also leads to better learning outcomes, as children learn better when they feel safe, supported, and valued by their peers and teachers. The collaborative learning environment creates positive classroom dynamics, enhancing the overall educational experience.
    </p>
    <p>
    The parents can support the children in practising active kindness by discussing and setting kindness goals, in conjunction with them. It can help individuals cultivate a habit of kindness and support intentional efforts to incorporate acts of kindness into their daily lives.
    </p>
    
    <h4>An easy way to create the family’s kindness goals is listed below.</h4>
    
    <ul>
    <li><strong>Family and personal values</strong> - Reflect on your values and find the alignment between them and kindness.</li>
    <li><strong>Focus areas</strong> - Determine how you want to practise kindness. It can be towards family, friends, colleagues, strangers, animals, or the environment. It can also include being emotionally available for loved ones.</li>
    <li><strong>Set your goals</strong> - Decide on the frequency of the acts of kindness. It can range from a daily act to a monthly volunteering, keeping the other commitments in mind.</li>
    <li><strong>Create an action plan</strong> - List down the ways to achieve the goals you have set for yourself. Include the process like brainstorming for ideas, finding the organisations to volunteer for, and scheduling the hours.</li>
    <li><strong>Monitor your progress</strong> - Regular monitoring will keep you motivated while helping you be accountable.</li>
    <li><strong>Let the plan evolve</strong> - The regular monitoring will give you multiple insights regarding your kindness goals, and be open to evaluating and adjusting them. You can expand the goals by increasing the frequency or adding new areas or people to be kind to.</li>
    <li><strong>Introspect</strong> - Assess the impact of your kindness campaign on yourself and others. Use the insights to refine your goals and broaden the scope of your work.</li>
    <li><strong>Kindness to Self</strong> - This is the most important aspect of the kindness journey. The goals should not add stress to your life. The process should be joyful as this journey is a reward in itself.</li>
    </ul>
    <p>Be kind to yourself throughout the process and celebrate the positive impact you are making in the world.</p>
    
    <p>Let us make the world a kinder place!</p>`,
  },
  {
    title: "Understanding Chinmaya Vision Programme",
    date: "15 November, 2023",
    id: "blog-vision-programme",
    link: "/blog/understanding-chinmaya-vision-programme",
    content: `<p><em>"Our Chinmaya Vidyalayas are determined to achieve the three-fold goal of education: vision, spirit of service, and efficiency."</em><br>
        - Swami Tejomayananda</p>
      
        <p>Pujya Gurudev Swami Chinmayananda believed the purpose of education is to broaden the mind, along with developing love and pride for the nation and creating a future adult who would be a positive asset for society. He viewed the children as makers of the future, the safekeepers of culture and traditions.</p>
      
        <p>Giving them a healthy safe space for growth, teaching them respect for all living creatures, and inculcating in love and pride for the nation and our cultural heritage, is our primary responsibility.</p>
      
        <p>His vision of education was formalized as Chinmaya Vision Programme which is the guiding light for all Chinmaya Educational Institutions. It makes the Chinmaya institutions as “Institutions With a Difference.”</p>
      
        <p><strong>CVP places the child at the center of all learning efforts and includes the following:</strong></p>
      
        <h2>Integrated Development</h2>
        <ul>
          <li>It means looking after each aspect of the child’s development and helping them in knowing, understanding, and loving themselves.</li>
          <li><strong>Physical development:</strong> Underlines the importance of physical health through hygiene and nutrition. The school includes physical exercises and several sports to ensure proper development of children.</li>
          <li><strong>Mental development:</strong> Children need to be able to label and understand their emotions to manage them. Harmony with oneself is essential to be in harmony with others, and this is achieved through emotional balance.</li>
          <li><strong>Intellectual development:</strong> Aims at helping children understand their unique intellectual capabilities and enhance them.</li>
          <li><strong>Spiritual development:</strong> Aims at helping the children understand their divinity and themselves. Value-education along with spiritual practices like meditation and yoga help the children level up.</li>
        </ul>
      
        <h2>Universal Outlook</h2>
        <p>As the world becomes more and more connected, the need for a universal vision that encompasses the entire world is felt increasingly. Children are encouraged to develop a thinking which isn’t limited by borders.</p>
      
        <h2>Indian Culture</h2>
        <p>Culture is not reflected merely in art forms but also in the way of thinking of a nation or civilization. Indian thought has always been universal, inclusive, and scientific.</p>
      
        <h2>Patriotism</h2>
        <p>India’s ancient texts have defined love and service for the motherland as an essential duty. Inculcating patriotism and national pride in children by educating them about the nation and their duties towards it is a major focus in all Chinmaya Institutions.</p>
      
        <p>Chinmaya Vision Programme enables the Vidyalaya to prepare future global citizens who take pride in their culture while developing a vision that is all-encompassing and takes the entire humanity ahead.</p>
      `,
  },
  {
    title: "Holistic Development - The Purpose of Education",
    date: "01 November, 2023",
    id: "blog-holistic-development",
    link: "/blog/holistic-development-the-purpose-of-education",
    content: ` <p>At Chinmaya Vidyalaya, Vasant Vihar, the students are placed at the centre of all the learning initiatives. It is the flagship school of the Chinmaya Education Cell, CCMTEC, that runs over 80 schools in the country.</p>
    
    <p>Chinmaya Vidyalayas are guided by Chinmaya Vision Programme, CVP, which is a translation of Swami Chinmayananda’s thoughts on education. It is the inspiration behind the student-centric approach to education followed by the Vidyalaya and also helps the students form a connection with Indian culture.</p>
    
    <p>It is this learner-centric approach that has helped Chinmaya Vidyalaya, New Delhi be consistently ranked among the finest schools in New Delhi. It has recently been adjudged the 4th Best School in South-West Delhi District by Times School Survey, 2023, and was ranked 1st in Unique Classroom Initiatives by Education Today.</p>
    
    <p>Ms. Shibani Khorana, the Manager of the Vidyalaya mentioned that the school management takes inspiration from the scriptures that dictate that “One should not be negligent of the truth, of duty, of welfare and self-study and teaching of higher knowledge.”</p>
    
    <p>The Chinmaya Vision Programme emphasizes holistic development that includes the mental, physical, spiritual and intellectual development of each child. The children are encouraged to develop a universal outlook and develop an understanding of the world around them. It also lays the foundation for developing patriotism, understanding the ancient Indian civilization, and taking pride in Indian cultural heritage.</p>
    
    <p>Swami Chinmayananda believed in the immense power of the youth to shape the country’s future, and that to be a true leader one needs to be firmly rooted in the culture.</p>
    
    <p>The Vidyalaya is ably guided by Ms. Archana Soni as the Principal. She is an eminent educationist with several awards to her credit, the most recent one being Top 50 Effective Principals by Education Today.</p>
    
    <p>Ms. Soni believes that knowledge is power and the path to true happiness. Under her leadership, the school continues to be future-ready through infrastructure development while still being connected with the wisdom of the Rishis.</p>
`,
  },
];

export default data;
