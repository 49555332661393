import React from "react";
import Layout from "../../../Layout/index.js";
import "../blog-page.scss";
import data from "../data.js";
import Seo from "../../../seo.js";

function SchoolWithADifferenceBlog() {
  const id = "chinmaya-vidyalaya-school-with-a-difference";
  const blogData = data.filter((item) => item.id === id)[0];

  return (
    <Layout>
      <Seo title={`Blog | ${blogData.title}`} description="" />
      <div className="blog-container">
        <a className="blog-back" href="/blog/">
          <i className="fa-solid fa-arrow-left"></i>Back
        </a>
        <div className="blog-article">
          <h1 className="blog-heading">{blogData.title}</h1>
          <div className="blog-date">{blogData.date}</div>
          <br />

          <div
            className="blog-content"
            dangerouslySetInnerHTML={{ __html: blogData.content }}
          ></div>
        </div>
      </div>
    </Layout>
  );
}

export default SchoolWithADifferenceBlog;
